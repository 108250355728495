<div class="test-divider">
  <app-container>
    <div class="test-divider__inner">
      <span
        >Портал працює у тестовому режимі.
        <a routerLink="/export/office">Приймаємо зауваження або пропозиції</a></span
      >
    </div>
  </app-container>
</div>

import { Component } from '@angular/core';
import { ContainerComponent } from '../container/container.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-test-divider',
  standalone: true,
  imports: [ContainerComponent, RouterLink],
  templateUrl: './test-divider.component.html',
  styleUrl: './test-divider.component.scss',
})
export class TestDividerComponent {}
